
<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			title="Asignación Equipos"
			add
			edit
			delete
			@save="save($event)"
			@rowSelected="rowSelected($event)"
			:single-row="false"
			ref="sAsignament"
			load
			@load="printQR()"
		>
		<template v-slot:filter>
                <v-container>
                    <v-row>                      
                        <v-col sm="4">
                            <v-select 
								:items="itemQrStatus"
								 item-value="qrStatus"
                                item-text="qrDesc"
								class="text"
                                label="Estado Qr"
								autocomplete
                                v-model="filter.QrStatus"
                            >
							
							</v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Nombre"
								v-model="props.item.TeaName"
								ref="refteanme"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Marca"
								v-model="props.item.TeaBrand"
								ref="refteabrand"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Serie"
								v-model="props.item.TeaSerie"
								ref="refteaserie"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="N° Document"
								v-model="props.item.TeaDocumentNumber"
								ref="refteadocumentnumber"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Tipo"
								v-model="props.item.TeaType"
								ref="refteatype"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Modelo"
								v-model="props.item.TeaModel"
								ref="refteamodel"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-date
								label="F. Inicio"
								v-model="props.item.TeaDateInitial"
								ref="refteadateinitial"
							></s-date>
						</v-col>
						<v-col class="s-col-form">
							<s-date
								label="F. Fin"
								v-model="props.item.TeaDateEnd"
								ref="refteadateend"
								clearable
							></s-date>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:TeaStatus="{ row }">
				<v-chip style="margin:0px" x-small :color="row.TeaStatus == 1 ? 'success' : 'error'">
					{{ row.TeaStatus == 1 ? "Activo" : "Vencido" }}
				</v-chip>
			</template>

			<template v-slot:QrStatus="{ row }">
				<v-chip style="margin:0px" x-small :color="row.QrStatus == 1 ? 'success' : 'warning'">
					{{ row.QrStatus == 1 ? "Asignado" : "No Asignado" }}
				</v-chip>
			</template>

			<template v-slot:qr="{ row }">
				<div>
					<v-btn
						x-small
						color="primary"
						@click="printQR()"
					>
						<v-icon style="font-size:18px;">mdi-qrcode</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:actionQr="{ row }">
				<div v-show="!row.QrStatus">
					<v-btn
						x-small
						@click="asignEquip(row.TeaID)"
					>
						<v-icon 
							style="font-size:35px;"
							color="orange darken-2"
						>mdi-arrow-up-bold-box-outline</v-icon>
					</v-btn>
				</div>
			</template>
		</s-crud>

		<!--  -->
		<!-- <v-row justify="center" style="display: none;">
			<v-col cols="12">
				<qr-code :text="textQR" error-level="H" :size="80"> </qr-code>
			</v-col>
		</v-row> -->
		<v-row justify="center">
			<v-col cols="6" v-for="i in itemsSelected">
				<qr-code 
					:text="
						i.TeaID
					" error-level="H" 
					:size="110"
					hidden
				>
				</qr-code>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import _sEquipmentAssignmentService from '@/services/InformationTechnology/EquipmentAssignmentService';
	import qrCode from "vue-qr-generator";

	export default {
		components: { qrCode },

		data() {
			return {
				filter: {
					QrStatus: 0
				},
				config: {
					model: {
						TeaID: "ID",
						TeaStatus: "int",
						qr: 'string',
						actionQr: 'string',
						QrStatus: 'string'
					},
					service: _sEquipmentAssignmentService,
					headers: [
						{text: "Qr", value: "qr"},
						{text: "Asignar Qr", value: "actionQr"},
						{text: "Qr Estado", value: "QrStatus"},
						{text: "ID", value: "TeaID"},
						{text: "Nombre", value: "TeaName"},
						{text: "Marca", value: "TeaBrand"},
						{text: "Serie", value: "TeaSerie"},
						{text: "Tipo", value: "TeaType"},
						{text: "Modelo", value: "TeaModel"},
						{text: "Trabajador", value: "WorkerName"},
						{text: "Area", value: "AreName"},
						{text: "Posición", value: "PstName"},
						{text: "N° Documento", value: "TeaDocumentNumber"},
						{text: "Estado", value: "TeaStatus"},
					]
				},
				textQR: "",
				selected: {},
				itemsSelected: [],
				itemQrStatus: [
					{
						"qrStatus": "0",
						"qrDesc": "Todo"
					},
					{
						"qrStatus": "1",
						"qrDesc": "Asignado"
					},
					{
						"qrStatus": "2",
						"qrDesc": "No Asignado" 
					}
				]
			}
		},

		methods: {
			save(item) {
				if(item.TeaName == ""){
					this.$refs.refteanme.error("Error, ingrese el nombre");
					return;
				}
				if(item.TeaBrand == ""){
					this.$refs.refteabrand.error("Error, ingrese marca");
					return;
				}
				if(item.TeaSerie == ""){
					this.$refs.refteaserie.error("Error, ingrese serie");
					return;
				}
				if(item.TeaDocumentNumber == ""){
					this.$refs.refteadocumentnumber.error("Error, ingrese número de documento");
					return;
				}
				if(item.TeaType == ""){
					this.$refs.refteatype.error("Error, ingrese tipo");
					return;
				}
				if(item.TeaModel == ""){
					this.$refs.refteamodel.error("Error, ingrese modelo");
					return;
				}
				if(item.TeaDateInitial == ""){
					this.$refs.refteadateinitial.error("Error, ingrese fecha inicial");
					return;
				}
				item.save();
			},

			rowSelected(rows)
			{
				rows.forEach(element => {
					element.TeaID = element.TeaID.toString();
				});
				this.itemsSelected = rows;
				/*
				console.log('forrr ', document.querySelector("img"));



				 if(rows.length > 0)
				{
					this.selected = rows[0];
					this.textQR =  this.selected.TeaID.toString();
				} */
				
			},

			printQR() {
				var imgAll =  []
				imgAll = document.querySelectorAll("img");
                console.log("🚀 ~ file: EquipmentAssignment.vue ~ line 204 ~ printQR ~ img", imgAll)

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +						
						"pt>function step1(){\n" +
						"setTimeout('step2()', 1);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='row'>",
				);

				/* pwa.document.write(
					"<div style='margin: 20px 20px 20px 0px; position:absolute;' id='col1' ><img alt='Scan me!' style='display: block;' src='" +
						img.currentSrc +
						"' /></div>",
				); */
				pwa.document.write(
					"<table><tr>"
				);
				
				imgAll.forEach(element => {
					pwa.document.write("<td><div style='margin: 0px 0px 0px 10px;'><img alt='Scan me!' style='display: block;'  src='" + element.currentSrc + "' /></div></td>")
					/* pwa.document.write("<td></td><div class='col col-6' style='margin: 10px 0px 0px 0px;'><img alt='Scan me!' style='display: block;'  src='" + element.currentSrc + "' /></div></td>"); */	
				});


				/* imgAll.forEach(element => {
				pwa.document.write("<d class='col'><img alt='Scan me!' style='display: block;'  src='" + element.currentSrc + "' /></d>");
					
				}) */

				
				//pwa.document.write("<br><br><div id='col2' style='margin: 20px 0px 0px 410px;'><table border='0'>");//WpbCorrelative

			
				pwa.document.write("</tr></table></div></body></html>");
				pwa.document.close();
			},
			asignEquip(teaID){
				this.$fun.alert("¿Desea cambiar el estado Qr?","question")
				.then((r) => {
					if(r.value == true && teaID > 0){					
						_sEquipmentAssignmentService.assignQr(teaID, this.$fun.getUserID())
							.then((resp) => {
								console.log(resp);
								this.$refs.sAsignament.refresh();
							});
					} 				
				});				
			},
		},
		 
	}
</script>
